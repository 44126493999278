/* All policy page's style */
.policyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1d1e2d;
}
.policyContainer .navBar {
  background: #1d2739;
}
.policyNav {
  width: 90%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.policyNavText {
  font-size: 10px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
.policyNavText:hover {
  color: #e4026e;
}
.policyNavActiveText {
  font-size: 10px !important;
  color: #e4026e;
  cursor: pointer;
}
.policyPageSection {
  width: 100%;
  border-top: 1px solid rgba(128, 128, 128, 0.404);
  display: flex;
  flex-direction: row;
  position: relative;
}
.sideMenuActive {
  width: 48%;
}
.sideMenuDeactive {
  width: 0px;
}
.sideMenuPadding {
  padding: 15px 0;
  padding-left: 3%;
  padding-right: 10px;
}
.policySideSection {
  border-right: 1px solid rgba(128, 128, 128, 0.404);
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #1d1e2d;
  transition: all 0.5s;
}
.sideArrowBtn {
  position: fixed;
  top: 50%;
  z-index: 3;
  opacity: 0.7;
  transition: all 0.5s;
}

.policyMainSection {
  width: 100%;
  padding: 20px;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}
.policyHeader {
  font-family: "Praxis-Heavy" !important;
  font-size: 25px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.policyHeader span {
  color: #e4026e;
  font-family: "Praxis-Heavy" !important;
}
.policyHeader1 {
  font-family: "Praxis-Heavy" !important;
  font-size: 20px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.policyHeader2 {
  font-family: "Praxis-Heavy" !important;
  font-size: 16px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.policyNText {
  font-size: 14px !important;
  color: #fff;
  margin-bottom: 10px !important;
}
.policyUlineText {
  font-size: 14px !important;
  text-decoration: underline;
  color: #fa4199;
  cursor: pointer;
}
.gapBox {
  margin: 40px 0;
}
.policyCenterImgBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.policyCenterImgBox img {
  width: 100%;
}
.redioTextLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}
.redioTextLine img {
  margin-right: 20px;
}
.redioTextLine p {
  color: #fff;
  font-size: 14px !important;
}
.redioTextLine span {
  color: #fa4199;
  font-weight: 600 !important;
}
.lastUpdateText {
  color: #e4026e;
  font-size: 14px !important;
}
.lastUpdateText span {
  color: rgb(202, 202, 202);
}
.policyColorHeader2 {
  font-size: 42px !important;
  font-family: "Praxis-Heavy" !important;
  color: #fff;
}
.policyColorHeader2 span {
  color: #e4026e;
  font-family: "Praxis-Heavy" !important;
}
.inShotText {
  font-size: 14px !important;
  color: #fff;
  margin-bottom: 10px !important;
}
.inShotText span {
  font-size: 16px !important;
  color: #e4026e;
  font-weight: 600 !important;
}
.sideMenuItemBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.sideMenuItemBox:hover p {
  color: #e4026e;
}
.sideMenuItemBox svg {
  min-width: 17px;
  margin-right: 7px;
  margin-top: 4px;
  transition: all 0.5s;
}
.sideMenuItemBox svg path {
  transition: all 0.5s;
}
.sideMenuItemBox:hover svg path {
  fill: #e4026e;
}

.sideMenuDropBtn {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  background: #fa4099;
  border-radius: 61px;
  gap: 7px;
  padding-left: 20px;
  padding-right: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.sideMenuDropBtn:hover {
  background: #ce357f;
}
.sideMenuDropBtn p {
  font-size: 10px !important;
  color: #fff;
}
.sideMenuDropBtn img {
  width: 8px;
  transition: all 0.5s;
}
.sideMenuMainBox {
  width: 100%;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
}
.PrivacyPage .sideMenuMainBox {
  overflow: scroll;
  height: 70vh;
  overflow-x: hidden;
}

.termsCondition .sideMenuMainBox {
  overflow: scroll;
  height: 70vh;
  overflow-x: hidden;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .policySideSection {
    width: 25%;
    padding: 16px 0;
    padding-left: 2%;
    padding-right: 16px;
    position: relative;
    background: unset;
  }
  .policyMainSection {
    width: 75%;
  }
  .policyHeader {
    font-size: 35px !important;
  }
  .policyHeader1 {
    font-size: 25px !important;
  }
  .policyHeader2 {
    font-size: 18px !important;
  }
  .policyNText {
    font-size: 16px !important;
  }
  .redioTextLine p {
    color: #fff;
    font-size: 16px !important;
  }
  .lastUpdateText {
    font-size: 16px !important;
  }
  .inShotText {
    font-size: 16px !important;
  }
  .inShotText span {
    font-size: 18px !important;
  }
  .policyUlineText {
    font-size: 16px !important;
  }
  .sideMenuDropBtn {
    width: 90%;
    min-width: 165px;
  }
  .sideArrowBtn {
    display: none;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .policyNavText {
    font-size: 16px !important;
  }
  .policySideSection {
    max-width: 363px;
    padding: 30px 0;
    padding-left: 3%;
    padding-right: 20px;
  }
  .sideMenuDropBtn p {
    font-size: 16px !important;
  }
  .sideMenuDropBtn img {
    min-width: 10px;
  }
  .policyNavActiveText {
    font-size: 16px !important;
  }
  .policyHeader {
    font-size: 50px !important;
  }
  .policyHeader1 {
    font-size: 36px !important;
  }
  .policyHeader2 {
    font-size: 24px !important;
  }
  .policyNText {
    font-size: 18px !important;
  }
  .redioTextLine p {
    font-size: 18px !important;
  }
  .lastUpdateText {
    font-size: 18px !important;
  }
  .inShotText {
    font-size: 18px !important;
  }
  .inShotText span {
    font-size: 20px !important;
  }
  .policyUlineText {
    font-size: 18px !important;
  }
  .policyCenterImgBox img {
    width: 60%;
  }
}
