.navBar {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #1d1e2d;
  align-items: center;
  padding: 0 3%;
}
.navItem {
  width: 140px;
  height: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.lanDrop {
  width: 140px;
  height: 40px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
}
.lanDrop p {
  font-size: 17px !important;
  font-weight: 600 !important;
}
.navNdBox {
  display: none;
  flex-direction: row;
}
.ClogoBox {
  width: 180px;
  height: 45px;
  position: relative;
  cursor: pointer;
}
.ClogoBox .cLog {
  width: 100%;
  height: 100%;
}
.ClogoBox .tmIcon {
  position: absolute;
  top: 0px;
  right: -19px;
}
.navNdBox .NIconBox {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background: #ffff;
}
.navNdBox .NIconBox img {
  width: 20px;
}
.navItem p {
  color: #fff;
  font-size: 13px !important;
}
.dropBox {
  width: 100%;
  position: absolute;
  top: 62px;
  left: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 2;
}
.dropBox .dropItem {
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  background: #fff;
  align-items: center;
  transition: all 0.5s;
  border-bottom: 1px solid rgb(196, 196, 196);
}
.dropBox .dropItem:hover {
  background: #e0e0e0;
}
.dropBox .dropItem p {
  color: #000;
}
.lanDropIcon {
  transition: all 0.8s;
}
.humMenuBox {
  display: block;
}
.humMenuBox .humIcon {
  cursor: pointer;
}
.sideMenu {
  height: 100vh;
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
  overflow: hidden;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}
.backIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.sideMenu .lanDrop {
  width: 230px;
  margin-top: 120px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .sideMenu {
    display: none;
  }
  .humMenuBox {
    display: none;
  }
  .navBar {
  }
  .navItem {
    width: 160px;
  }
  .navNdBox {
    display: flex;
  }
  .navItem p {
    font-size: 16px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .navBar {
    height: 70px;
    padding: 0 6%;
  }
  .navItem {
    width: 185px;
  }
}
