.chNumberInput {
  width: 260px;
  height: 50px;
  background: rgb(128 128 128 / 9%);
  border: 1px solid rgba(128, 128, 128, 0.507);
  padding-left: 20px;
  border-radius: 7px;
}
.chNumberInput:focus {
  outline: none;
}
