.thankPop .appBtn {
  position: relative;
}

.thankPop .otpExpText {
  position: absolute;
  top: -23px;
  font-size: 13px !important;
  color: rgb(65, 212, 163);
  font-weight: 600 !important;
}
