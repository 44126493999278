.footer {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  background: #1d2739;
  position: relative;
}
.chatIcon {
  position: absolute;
  top: -23px;
  right: 7px;
  width: 65px;
  cursor: pointer;
  display: none;
}
.footerBottombox {
  width: 100%;
  height: 60px;
  border-top: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerBottombox p {
  font-size: 12px !important;
  color: #fff;
  text-align: center;
}
.footerBottombox span {
  text-decoration: underline;
  cursor: pointer;
  margin: 0 10px;
}
.footerMainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px 0;
  gap: 35px;
}
.footerItemBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerIconBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
}
.footerIconBox img {
  cursor: pointer;
}
.foB1Text {
  color: #fff;
  margin: 20px 0 !important;
  text-align: center;
}
.storBtnBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.storBtnBox img {
  cursor: pointer;
}
.callInputbox {
  width: 100%;
  height: 60px;
  border-radius: 12px;
  background: #fff;
  position: relative;
}
.callInputbox input {
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 20px;
  background: #ffffff00;
  border-radius: 12px;
  font-family: "Inter" !important;
  font-size: 16px;
}
.callInputbox input:focus {
  outline: none;
}
.callBtn {
  position: absolute;
  top: 6px;
  right: 5px;
  width: 33%;
  height: 48px;
  border-radius: 12px;
  background: #e4026e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  z-index: 2;
  cursor: pointer;
}

.callBtndisable {
  pointer-events: none;
}
.callBtn:hover {
  background: #ac0254;
}
.callBtn p {
  font-size: 11px !important;
  color: #fff;
  font-weight: 400 !important;
  font-family: "Praxis-Heavy" !important;
}
.callInputB {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.callInputB .homeContactBox {
  gap: 0px;
  margin-top: 20px;
  justify-content: center;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .chatIcon {
    top: -104px;
    right: 63px;
    width: 90px;
  }
  .storBtnBox {
    flex-direction: row;
  }
  .callInputbox {
    width: 53%;
  }
  .footerBottombox p {
    font-size: 16px !important;
  }
  .callBtn p {
    font-size: 17px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .footer {
    padding: 0 70px;
  }
  .footerMainSection {
    flex-direction: row;
    gap: unset;
  }
  .footerItemBox {
    width: 32%;
    align-items: start;
  }
  .storBtnBox {
    flex-direction: column;
  }
  .callInputB .homeContactBox {
    justify-content: start;
  }
  .callInputbox {
    width: 100%;
  }
  .foB1Text {
    text-align: start;
  }
  .callInputbox input {
    font-size: 16px;
  }
}
